<template>
    <div class="box box-widget">
        <div class="box-body">
            <div class="row">
                <datatable :columns="columns" :data="rows.data" class="table table-striped" v-if="rows.data.length > 0"></datatable>
                <span v-if="rows.data.length == 0" class="px-5 py-4" style="color: #7E7E7E;">Nenhum registro encontrado</span>
            </div>
        </div>
    </div>
</template>

<script>

    //import DatatableFactory from 'vuejs-datatable';

    export default {
        //components: { DatatableFactory },
        props: [
            "rows",
            "url"
        ],
        data(){
            return {
                columns: [
                    {label: "#", field: 'id', headerClass: 'text-left font-weight-bold'},
                    {label: "Assunto", field: 'subject', headerClass: 'text-left font-weight-bold'},
                    {label: "Prioridade", field: 'priority', headerClass: 'text-left font-weight-bold', representedAs: ({priority}) => `<span class='badge badge-primary'>${priority}</span>`, interpolate: true},
                    {label: "Data de Criação", field: 'created_at', headerClass: 'text-left font-weight-bold'},
                    {label: "Última Atividade", field: 'updated_at', headerClass: 'text-left font-weight-bold'},
                    {label: "Status", field: 'status', headerClass: 'text-left font-weight-bold', representedAs: ({status}) => `<span class='badge badge-success'>${status}</span>`, interpolate: true},
                    {label: "Ações", headerClass: 'text-left font-weight-bold', representedAs: ({url, id}) => `<a href='${this.url}${id}/details/'><i class="fa fa-info-circle"></i></a>`, interpolate: true},
                ],
                //rows: [],
                page: 1,
                per_page: 1000,
            }
        },
        methods:{
            getUsers: function() {
                axios.get('/users').then(function(response){
                    this.rows = response.data;
                }.bind(this));
            }
        },
        created: function(){
            console.log("this.rows");
            console.log(this.rows);
        }
    }
</script>

<style lang="scss" scoped>

.table-bordered thead th{
    font-weight: 600;
}

</style>