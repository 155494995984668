<template>
    <div>
    <v-select
        :multiple="multiple"
        label="name"
        :reduce="label => label.id.toString()"
        :options="options"
        v-model="selected"
        >
        <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!selected"
              v-bind="attributes"
              v-on="events"
            />
        </template>
    </v-select>    
    <input type="hidden" v-model="selected" name="formulario_id"/>    
    </div>
</template>
<script>
export default {
    props: [
        'options',
        'name',
        'disabled',
        'selected',
        'multiple',
    ],
    created() {
        if (this.selected){
            this.selected = JSON.parse(this.selected).map(function(item){
               return item.toString();
            });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>