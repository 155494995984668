<template>
    <div>
        <v-select label="label" :reduce="label => label.code" v-model="selected" value.sync="company_code" :options="options" name="company_code" :disabled="disabled" :selected="selected"></v-select>
        <input type="hidden" v-model="selected" name="company_code" />
    </div>
</template>
<script>
export default {
    props: ['options', 'name', 'disabled', 'selected'],
    data() {
        return {
            company_code: null
        }
    }
}
</script>