<template>
    <div class="col-12 p-2 text-right">
        <VueCtkDateTimePicker v-model="data_entrada" aria-label="Período" class="date-picker" locale="pt-BR" :no-header="true" :overlay="true" :range="true" :formatted="'ll'" :format="'YYYY-MM-DD'" :label="'Período'" :custom-shortcuts="labels"></VueCtkDateTimePicker>
        <input v-if="data_entrada" type="hidden" name="date_init" :value="computedInitDate">
        <input v-if="data_entrada" type="hidden" name="date_finish" :value="computedFinishDate">
    </div>
</template>
<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
    props: [
        "selected_init",
        "selected_finish",
      ],
    data() {
        return {
            data_entrada: null,
            labels:[
              { key: 'thisDay', label: 'Hoje', value: 'day' },
              { key: 'last15Days', label: 'Últ. 15 dias', value: 15 },
              { key: 'thisMonth', label: 'Este mês', value: 'month' },
              { key: 'lastMonth', label: 'Mês passado', value: '-month' }
            ]
        }
    },
    created() {
        if(this.selected_init != 'null'){
            this.data_entrada = { start: this.selected_init, end: this.selected_finish };
            console.log(this.data_entrada);
        }
      },
    computed: {
        computedInitDate() {
          return this.data_entrada.start;
        },
        computedFinishDate() {
          return this.data_entrada.end;
        },
      },
    components: {
        VueCtkDateTimePicker
    },
};
</script>
<style lang="scss" scoped>
</style>