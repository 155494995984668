<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div :class="filter_list ? 'col-lg-3' :'col-md-8 col-sm-6'">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div v-if="busca != 'false'" :class="filter_list ? 'col-lg-9 mt-2 mt-lg-0' : 'col-md-4 col-sm-6'">
          <form method="GET" class="form-horizontal" :action="url">
            <div class="input-group">
              
            </div>
            <div class="input-group">
              <!-- Filtrar por data -->
              <vue-monthly-picker data-text="Data Inicial" class="vue-month-init-title mt-2 mt-lg-0" v-if="date_init" :monthLabels="['Janeiro', 'Fevereiro','Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']" v-model="selectedMonthInit" dateFormat="MM/YYYY"></vue-monthly-picker>
              <input v-if="date_init" type="hidden" name="date_init" :value="computedMonthInit">
              <vue-monthly-picker data-text="Data Final" class="vue-month-init-title mt-2 mt-lg-0" v-if="date_finish" :monthLabels="['Janeiro', 'Fevereiro','Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']" v-model="selectedMonthFinish" dateFormat="MM/YYYY"></vue-monthly-picker>
              <input v-if="date_finish" type="hidden" name="date_finish" :value="computedMonthFinish">

              <!-- Filtrar por seleção -->
              <select v-if="filter_list" name="filter_list" id="filter_list" class="form-control" style="margin-right:5px;">
                <option value="">Selecione</option>
                <option v-for="(item, index) in filter_list" :key="index" :value="item.value" :selected="filter_list_selected == item.value ? true : false">{{item.label}}</option>
              </select>

              <!-- Filtro Geral -->
              <input
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                :value="busca"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  class="btn btn-default btn-flat"
                  data-toggle="tooltip"
                  title="Limpar Busca"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th v-if="!not_deletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th v-if="actions != false">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!not_deletable" align="center" class="v-middle">
                <input class="checkbox-delete" type="checkbox" name="registro[]" :value="item.id" />
              </th>
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type">{{field}}</span>

                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width:150px; max-height:150px;" />
                </span>

                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"
                  :class="'badge-' + field.status"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'reenviar'"
                >
                  {{ field.text }}
                  <a
                    class="badge"
                    :class="'badge-' + field.status"
                    :href="url + '/reenviar/' + field.input_id"
                  >Reenviar</a>
                </span>

                <span
                  v-else-if="field.type == 'link'"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-primary'"
                    :href="field.path"
                    title="Acessar"
                    target="_blank"
                  >
                    <i class="fa fa-link" style="color:#fff;"></i>
                  </a>&nbsp;
                </span>

                <span
                  v-else-if="field.type == 'action'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i>
                  </a>&nbsp;
                </span>
              </td>
              <td v-if="!actions && actions != false" class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  :href="url + '/' + item.id + '/edit'"
                  data-toggle="tooltip"
                  title="Editar"
                >
                  <i class="fa fa-pencil"></i>
                </a>
              </td>
              <td class="v-middle" v-if="actions && actions != false">
                <span v-for="(action, index) in actions" :key="index">
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="url + '/' + actionUrl(action.path, item.id)"
                    :title="action.label"
                    v-if="(action.label != 'Imagens' || item.id == 6) &&  action.label != 'Visualizar'"
                  >
                    <i :class="action.icon"></i>
                  </a>
                  <a
                    v-if="action.label == 'Visualizar'"
                    data-tooltip="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :title="action.label"
                    data-toggle="modal"
                    data-target="#trackingModal"                    
                    @click="dataViewTracking = filterTracking(item.id, items_complete.data)"
                    style="color:#fff;"
                  >
                    <i :class="action.icon"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="!not_deletable">
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <button
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
          <tfoot v-if="not_deletable">
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>

    <!-- MODAL VIZUALIZAÇÃO DO RASTREAMENTO -->
    <div class="modal fade" id="trackingModal" tabindex="-1" role="dialog" aria-labelledby="trackingModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          <h4 class="modal-title" id="trackingModalLabel" v-if="dataViewTracking">Detalhes do Rastreamento {{dataViewTracking.id}}</h4>  
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body" v-if="dataViewTracking">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr>
                  <td class="font-weight-bold">Origem</td>
                  <td>{{dataViewTracking.origin}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Data</td>
                  <td>{{dataViewTracking.date}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Código do Caso</td>
                  <td>{{dataViewTracking.case_id}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Código da Empresa</td>
                  <td>{{dataViewTracking.company_code}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Atividade</td>
                  <td>{{dataViewTracking.activity}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Recurso</td>
                  <td>{{dataViewTracking.resource}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Tipo</td>
                  <td>{{dataViewTracking.type}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Mensagem</td>
                  <td>{{dataViewTracking.message}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <span class="pull-right">
              <button type="button" class="btn btn-primary" data-dismiss="modal">
                Ok
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "date_init",
    "selected_init",
    "date_finish",
    "selected_finish",
    "filter_list",
    "filter_list_selected",
    "url",
    "token",
    "formClass",
    "not_deletable",
    "actions",
    "items_complete"
  ],
  data: function() {
    return {
      deleteItems: [],
      dataViewTracking: null,
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false,
      },
      selectedMonthInit: null,
      selectedMonthFinish: null
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
    // if (!this.items_complete.data) {
    //   this.items_complete.data = this.items_complete;
    // }
    if(this.selected_init){
      this.selectedMonthInit = this.selected_init; 
    }
    if(this.selected_finish){
      this.selectedMonthFinish = this.selected_finish; 
    }
  },
  computed: {
    computedMonthInit() {
      if(this.selectedMonthInit!=null){
        this.$emit('getPeriod', this.selectedMonthInit)
      }
      return this.selectedMonthInit;
    },
    computedMonthFinish() {
      if(this.selectedMonthFinish!=null){
        this.$emit('getPeriod', this.selectedMonthFinish)
      }
      return this.selectedMonthFinish;
    }
  },
  methods: {
    checkAll: function() {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function(e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    },
    actionUrl: (path, id) => {
      if (path){
        return path.replace(/{item}/g, id);
      }
    },
    filterTracking: (id, array) => {
      if (array){
        return array.find(function (item){
          return item.id === id
        })
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.box-header {
  .vue-monthly-picker {
    width: 20%;
    margin-right: 5px;
  }

  .vue-month-init-title, .vue-month-finish-title {
    position: relative;
    &:before {
      content: attr(data-text);
      position: absolute;
      left: 15px;
      top: -20px;
      color: #495057;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      z-index: 1;
    }

    .input{
      border-radius: 0 !important;
      box-shadow: none;
    }
  }
  @media (max-width: 575px) {
    .input-group{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
