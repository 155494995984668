<template>
    <div class="col-12 listagem_faq">
        <div class="col-12 box_acc" v-for="(item, index) in items.data" :key="index">
            <div class="col-12 header_acc" @click="toggleAcc(index)">
                <div class="box_text">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                    <span>{{item.ask}}</span>
                </div>
                <div class="box_seta">
                    <i class="fa fa-chevron-down" aria-hidden="true" :class="'seta_'+index"></i>
                </div>
            </div>
            <div class="col-12 conteudo_acc" :class="'conteudo_'+index">
                <div class="interno_conteudo" v-html="item.answer"></div>
            </div>
        </div>
        <div class="nenhum_registro" v-if="items.data.length == 0">
            Nenhum registro encontrado.
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "dados",
    ],
    data() {
        return {
            items: this.dados
        };
    },
    methods: {
        toggleAcc: function(index) {
            var content = ".conteudo_"+index;
            var seta = ".seta_"+index;
            $(".conteudo_acc").slideUp();

            if ($(content).is(":visible")){ // EXPANDIDO
                $(content).slideUp();
            } else { // RECOLHIDO
                $(content).slideDown();
            }
            $(seta).toggleClass('rotate_seta');
        },
    },
};
</script>
<style lang="scss" scoped>

</style>