<template>
	<div class="box box-widget">
		<div class="box-header with-border">
			<h3 class="box-title">{{title}}</h3>
		</div>
		
		<form :class="formClass" :action="url" v-on:submit="btnLoading" :method="method == 'GET' ? method : 'POST'" enctype="multipart/form-data">
			<input type="hidden" name="_token" :value="token" />
			<input type="hidden" name="_method" :value="method" />
			<div class="box-body">
				
				<slot></slot>

				<div class="form-group row">
					<label for="destiny" class="col-sm-2 control-label">Destino *</label>
					<div class="col-sm-5">
						<select class="form-control" name="destiny" id="destiny" required v-model="destiny" @change="changeDestiny()">
						<option value="0" selected>Api</option>
						<option value="1">E-mail</option>
						</select>
					</div>
				</div>
				<div class="form-group row">
					<label for="email_destiny" class="col-sm-2 control-label">E-mail de Destino <span v-if="destiny == 1">*</span></label>
					<div class="col-sm-10">
						<input type="text" name="email_destiny" class="form-control" id="email_destiny" maxlength="250" v-model="email_destiny" :disabled="destiny == 0" :required="destiny == 1">
					</div>
				</div>
				<div class="form-group row">
					<label for="email_title" class="col-sm-2 control-label">Título do E-mail <span v-if="destiny == 1">*</span></label>
					<div class="col-sm-10">
						<input type="text" name="email_title" class="form-control" id="email_title" maxlength="250" v-model="email_title" :disabled="destiny == 0" :required="destiny == 1">
					</div>
				</div>
				<div class="form-group row">
					<label for="file_name" class="col-sm-2 control-label">Nome do Arquivo *</label>
					<div class="col-sm-10">
						<input type="text" name="file_name" class="form-control" id="file_name" maxlength="250" required v-model="file_name">
					</div>
				</div>
				<div class="form-group row">
					<label for="type" class="col-sm-2 control-label">Tipo *</label>
					<div class="col-sm-5">
						<select class="form-control" name="type" id="type" required v-model="type" @change="type == 1 ? populated = 1 : populated = 0">
						<option value="0" selected>Padrão</option>
						<option value="1">Tabela</option>
						</select>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-sm-12">
						<div class="row">
							<label for="populated" class="col-sm-2 control-label">Populado?</label>
							<div class="col-sm-10">
								<input type="checkbox" name="populated" id="populated" v-model="populated" value="1" maxlength="191">
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label for="data_origin" class="col-sm-2 control-label">Origem dos Dados <span v-if="populated && !edit">*</span></label>
					<div class="col-sm-10">
						<!-- <input type="text" name="data_origin" class="form-control" id="data_origin" maxlength="250" :required="populated"> -->
						<input type="file" name="data_origin" @change="readFile" :required="populated && !edit" :disabled="!populated" accept="text/csv">
						<span class="help-block ml-2" style="color: #FF9800;font-size: 13px;">
							Somente arquivos CSV
						</span>
						<span class="w-100 pull-left mt-2" style="font-size: 11px;" v-if="edit">
							<span v-if="edit.data_origin">
								<span class="font-weight-bold">Selecionado anteriormente:</span>
								<a :href="edit.link_data_origin">{{edit.data_origin}}</a>
							</span>
						</span>
					</div>
				</div>

				<div class="form-group row">
					<label for="file_name" class="col-sm-2 control-label">Campos do Formulário *</label>
					<div class="col-sm-10">
						<input type="text" class="form-control pull-left" style="width: 300px;" placeholder="Nome do campo" v-model="fieldName">
						<button type="button" class="btn btn-light btn-flat pull-left ml-2" :disabled="!fieldName || (type == 1 && !populated)" @click="addField()"><i class="fa fa-plus"></i> Adicionar</button>
					</div>
					<input type="hidden" name="fieldsAdd" :value="JSON.stringify(fieldsAdd)">
					<input type="hidden" name="fieldsRemove" v-model="fieldsRemove">
					<input type="hidden" name="listFields" :value="JSON.stringify(listFields)">
					<input type="hidden" name="initialFields" :value="JSON.stringify(initialFields)">
				</div>

				<div class="form-group row">
					<label for="file_name" class="col-sm-2 control-label"></label>
					<div class="col-sm-10">
						<div class="form-group row">
							<span v-if="listFields.length == 0" style="margin-left: 15px; color: #ccc;">Nenhum campo adicionado.</span>
							<div class="col-sm-12" v-if="listFields.length > 0">
								<div class="row mx-0">
									<div class="col-sm-3 px-0 py-2 font-weight-bold">Nome do campo</div>
									<div class="col-sm-4 py-2 font-weight-bold" v-if="fieldsAssociate.length > 0">Campo do CSV</div>
								</div>
							</div>
							<div class="col-sm-12" v-for="(item, index) in listFields" :key="index">
								<div class="row mx-0">
									<input type="text" name="fields[]" class="col-sm-3 form-control" v-model="item.field" :disabled="edit">
									<div class="col-sm-4" v-if="fieldsAssociate.length > 0">
										<select class="form-control" name="fieldAssociates[]" id="local" v-model="item.associated">
											<option value="">Sem vínculo</option>
											<option :value="f" v-for="(f, index2) in fieldsAssociate" :key="index2">{{f}}</option>
										</select>
									</div>
									<div class="col-sm-2 d-flex align-items-center">
										<input class="pull-left" type="checkbox" name="fieldReadonly[]" :id="index" value="1" v-model="item.readonly"> 
										<label :for="index" class="control-label ml-2 mb-0" style="font-size: 14px;">Somente leitura</label>
									</div>
									<input type="hidden" name="valueDefaults[]" v-model="associateValues[item.associated]">
									<div class="col-sm-1 px-0 d-flex align-items-center">
										<button class="btn btn-light btn-sm ml-2" type="button" @click="deleteLine(index, item)" style="margin-bottom: 4px;">
											<i class="fa fa-times"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>			
			<div class="box-footer">
				<button type="submit" class="btn btn-success btn-flat ctaButton" :disabled="btnGravar.disabled"><i :class="btnGravar.icon"></i> {{ btnGravar.text }}</button>
				<a v-if="cancelUrl" :href="cancelUrl" class="btn btn-default btn-flat">Cancelar</a>
			</div>
			
		</form>
	</div>
</template>

<script>
	export default {
		props: ['title', 'url', 'cancelUrl', 'token', 'method', 'formClass', 'edit'],
		data : function(){
			return {
				btnGravar : {
					'icon' : 'fa fa-check',
					'text' : 'Gravar',
					'disabled' : false
				},
				populated: false,
				type: 0,
				destiny: 0,
				email_destiny: '',
				email_title: '',
				file_name: '',
				fieldName: '',
				listFields: [],
				fieldsAssociate: [],
				associateValues: [],
				initialFields: [],
				fieldsAdd: [],
				fieldsRemove: [],
			}
		},
		created() {
			if (this.edit){
				console.log("this.edit");
				console.log(this.edit);
				this.destiny = this.edit.destiny;
				this.email_destiny = this.edit.email_destiny;
				this.email_title = this.edit.email_title;
				this.file_name = this.edit.file_name;
				this.type = this.edit.type;
				this.populated = this.edit.populated;
				this.edit.fields.forEach(element => {
					this.listFields.push({
						"id": element.id,
						"field": element.name,
						"associated": element.associated_field ? element.associated_field : '',
						"readonly": element.readonly,
					});
					if (element.associated_field){
						this.fieldsAssociate.push(element.associated_field);
					}
				});
				this.initialFields = Object.assign([], this.listFields);
			}
		},
		methods : {
			btnLoading : function(e){
				this.btnGravar.icon = 'fa fa-spinner fa-pulse';
				this.btnGravar.text = 'Gravando';
				this.btnGravar.disabled = true;
			},
			
			addField(){
				var item = {
					"field": this.fieldName,
					"associated": '',
				};
				this.listFields.push(item);
				this.fieldName = '';
				if (this.edit){
					this.fieldsAdd.push(item);
				}
			},

			deleteLine(index, item) {
				this.listFields.splice(index, 1);
				if (this.edit){
					if (item.id){
						this.fieldsRemove.push(item.id);
					} else {
						if (this.fieldsAdd.length > 0){
							var self = this;
							this.fieldsAdd.forEach(function(value, indexx) {
								if (value.field == item.field){
									self.fieldsAdd.splice(indexx, 1);
								}
							});
						}
					}
				}
			},

			changeDestiny(){
				if (this.destiny == 0){
					this.email_destiny = '';
					this.email_title = '';
				}
			},

			readFile(){
				this.fieldsAssociate = [];
				var fileToParse = event.target.files[0];
				var self = this;
				if (fileToParse){
					this.$papa.parse(fileToParse, {
						header: true,
						complete: function (results) {
							if (results.data.length){
								console.log("readFile CSV");
								console.log(results.data);
								self.fieldsAssociate = Object.keys(results.data[0]);
								self.associateValues = results.data[0];
								if (self.edit){
									self.listFields = [];
								}
								self.fieldsAssociate.forEach(element => {
									self.listFields.push({
										"field": element,
										"associated": element,
									});
								});
							}
						}
					});
				}
			},
			
		}
	}
</script>
