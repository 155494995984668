<template>
  <div>
    <vue-monthly-picker :monthLabels="['Janeiro', 'Fevereiro','Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']" v-model="selectedMonth" :min="min" :max="max" dateFormat="MM/YYYY" :placeHolder="placeholder" :inputClass="classInput" :disabled="disabledInput"></vue-monthly-picker>
    <input type="hidden" :name="name" :value="computedMonth">
  </div>
</template>
<script>
import moment from 'moment';

export default {

    props: ['selected', 'name', 'min', 'max', 'placeholder', 'classInput', 'disabledInput'],
    data() {
        return {
            selectedMonth: null
        }
    },
    mounted() {
        if(this.selected){
            this.selectedMonth = this.selected; 
        }
    },
    computed: {
        computedMonth() {
            if(this.selectedMonth!=null){
                //this.$emit('getPeriod', this.selectedMonth)
                this.$emit('getPeriod', moment(this.selectedMonth).format('MM/YYYY'))
            }
            //return this.selectedMonth;
            if (this.selectedMonth){
                return moment(this.selectedMonth).format('YYYY/MM/DD');
            } else {
                return null;
            }
        }
    }
};
</script>