<template>
  <div class="col-12 p-2 text-right" style="box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);">
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :useCustomSlot="true"
      :options="options"
      @vdropzone-queue-complete="endAndClose"
      @vdropzone-error="error"
      @vdropzone-file-added="vfileAdded"
    >
        <div class="col-12 mb-3">
            <!-- <i class="fa fa-angle-up fa-5x" aria-hidden="true" style="color: #c0c0c0;"></i> -->
            <img src="img/upload.png">
        </div>
        <div class="col-12 mb-2">
            <h1 class="font-weight-bold" style="font-size: 21px;color: #2C2C2C;">Adicionar arquivos</h1>
        </div>
        <div class="col-12" style="color: #333333;">
            Clique ou arraste aqui os arquivos que deseja enviar
        </div>
    </vue-dropzone>
    <div class="text-center box_valid" v-if="validFile">
      Selecione um arquivo.
    </div>
    <br />
    <form @submit.prevent="onFormSubmit">
      <div class="form-group row">
        <label class="col-sm-12 text-center">Selecione o período *</label>
        <div class="col-sm-12">
          <month-picker required name="period" @getPeriod="setPeriod" style="width:200px;margin:0 auto;"></month-picker>
          <div class="text-center box_valid" v-if="validPeriod">
            Selecione um período.
          </div>
        </div>
        <div class="col-sm-12 text-center mb-4 mt-3">
          <!-- <button type="button" @click="close" class="btn btn-danger mt-2">
            <i class="fa fa-close"></i> Cancelar
          </button> -->
          <!-- <button type="submit" class="btn btn-primary mt-2 btn_enviar_insumos" :disabled="(sending && hasFile) || period == null">
            Enviar Insumos
          </button> -->
          <button type="submit" class="btn btn-primary mt-2 btn_enviar_insumos" :disabled="loading">
            <span v-if="!loading">
              <i class="fa fa-upload"></i> Enviar Arquivos
            </span>
            <span v-if="loading">
              <i class="fa fa-spinner fa-pulse"></i> Enviando
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import moment from 'moment';

export default {
  name: "app",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: ["url"],
  data: function() {
    return {
      period: null,
      sending: false,
      hasError: false,
      hasFile: false,
      validFile: false,
      validPeriod: false,
      loading: false
    };
  },
  computed: {
    options() {
      return {
        url: this.url + "&period=" + this.period,
        thumbnailWidth: 150,
        thumbnailHeight: 250,
        maxFilesize: 50,
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: {
          "X-CSRF-TOKEN": decodeURIComponent(
            document.head.querySelector('[name="csrf-token"]').content
          )
        }
      };
    }
  },
  methods: {
    vfileAdded(file) {
      this.hasFile = true;
      this.validFile = false;
    },
    onFormSubmit() {
      if (!this.hasFile){
        this.validFile = true;
        return false;
      }
      if (!this.period){
        this.validPeriod = true;
        return false;
      }
      this.loading = true;
      this.sending = true;
      this.hasError = false;
      this.$refs.myVueDropzone.dropzone.options.url = this.url + "&period=" + this.period;
      this.$refs.myVueDropzone.processQueue();
    },
    setPeriod(period) {
      //this.period = period.format();
      this.period = moment(period, 'MM/YYYY').format('YYYY-MM-DD');
      this.validPeriod = false;
    },
    close() {
      this.$refs.myVueDropzone.removeAllFiles(true);
      $("#modalUpload").modal("hide");
    },
    error(files, message, xhr) {
      console.log("error");
      console.log(message);
      this.hasError = true;
      this.$swal({
        title: "Erro!",
        text: message,
        type: "danger"
      });
    },
    endAndClose(a) {
      console.log("a");
      console.log(a);
      this.sending = false;
      if (!this.hasError) {
        this.$swal({
          title: 'Sucesso!',
          text: 'Arquivo enviado com sucesso.',
          type: 'success',
          showConfirmButton: true          
        }).then((result) => {
          console.log("result");
          console.log(result);
          if (result.value) {
            this.close();
            location.reload();
          }
        })
        /*setTimeout(() => {
          this.close();
          location.reload();
        }, 2000);*/
      }
    }
  }
};
</script>

<style lang="scss" scoped>

  .dz-progress{
    display:none !important;
  }
    .vue-dropzone {
        border: 1px dashed #E0E0E0;
        font-family: 'Roboto', sans-serif;
    }

    .box_valid{
      font-size: 13px;
      padding: 5px;
      color: red;
    }

    .btn_enviar_insumos{
        background-color: #FFFFFF !important;
        border: 1px solid #18A0FB !important;
        border-radius: 5px;
        color: #18A0FB !important;
    }

    .btn_enviar_insumos:hover {
        background-color: #18A0FB !important;
        color: #FFFFFF !important;
    }
</style>