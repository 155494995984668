<template>    
    <div class="form-group">

        <!-- CAMPOS DE SELEÇÃO -->
        <div class="form-group row d-flex justify-content-center align-items-start">
            <div class="col-sm-4">
                <select
                    class="form-control"
                    name="form"
                    id="form"
                    v-model="form"
                    :disabled="acao || forms.length == 0"
                    @change="verifPeriod()"
                >
                    <option value="" selected>Selecione um formulário</option>
                    <option :value="item" v-for="(item, index) in forms" :key="index">{{item.title}}</option>
                </select>
            </div>
            <div class="col-sm-3">
                <select
                    class="form-control"
                    name="period"
                    id="period"
                    :disabled="months.length == 0 || (acao && acao.status == 1)"
                    v-model="period"
                >
                    <option value="" selected>Período</option>
                    <option :value="item" v-for="(item, index) in months" :key="index">{{item}}</option>
                </select>
                <span style="font-size: 12px;color:red;" v-if="formSelected && months.length == 0">Nenhum período disponível.</span>
                <span style="font-size: 12px;color:#FF9800;" v-if="!formSelected">Selecione um formulário.</span>
                <!-- <month-picker
                    required
                    name="period"
                    :min="computedMinDate"
                    :max="computedMaxDate"
                    @getPeriod="setPeriod"
                    placeholder="Período"
                    :selected="period"
                ></month-picker> -->
            </div>
            <button v-if="!acao" type="button" @click="openAction()" class="btn btn-primary btn_enviar_insumos" :disabled="loading || !form || !period || baseForm.length > 0">
                <span v-if="!loading">
                    Iniciar preenchimento
                </span>
                <span v-if="loading">
                    <i class="fa fa-spinner fa-pulse"></i> Aguarde...
                </span>
            </button>
        </div>
        
        <!-- PREENCHIMENTO DO FORMULÁRIO -->
        <div v-if="loading" class="d-flex justify-content-center py-5 mt-5">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="form-group mt-5" v-if="acao && !loading">
            <div class="row">
                <div class="col-sm-12">
                    <div class="pull-left">
                        <h1 class="card-title" style="font-size: 25px;">{{form.title}}</h1>
                    </div>
                    <div class="pull-right ml-1" style="font-size:10px; color: #858585;">
                        <span v-if="loadingAutoSave">
                            <i class="fa fa-spinner fa-pulse"></i>
                            Salvando...
                        </span>
                        <span v-if="!loadingAutoSave && saveTime">Alterações salvas às {{saveTime}}</span>
                    </div>
                </div>
                <div class="col-sm-12">
                    <h6 class="card-subtitle mb-2 text-muted">{{form.description}}</h6>
                    <span v-if="acao">
                        <span class="badge badge-info pull-left" v-if="acao.status == 0">Aberto</span>
                        <span class="badge badge-success pull-left" v-if="acao.status == 1">Fechado</span>
                    </span>                    
                    <hr/>
                </div>
            </div>

            <!-- FORMULÁRIO PADRÃO -->
            <div class="form-group row mt-2 px-0" v-if="form.type == 0">
                <div class="col-sm-12 px-0 mb-2" v-for="(item, index) in baseForm" :key="index">
                    <div class="form-group row">
                        <label class="col-sm-4 control-label">{{item.name}}</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control form-control-sm" v-model="item.value" @keyup="autoSave(item)" :disabled="acao.status == 1 || item.readonly" v-if="!item.default">
                            <input type="text" class="form-control form-control-sm" v-model="item.value" :disabled="item.default || acao.status == 1 || item.readonly" v-if="item.default">
                        </div>
                    </div>
                </div>
            </div>

            <!-- FORMULÁRIO TABELA -->
            <div class="form-group row mt-2 px-3" v-if="form.type == 1">
                <div class="w-100 pull-left px-0">
                    <div class="form-group row">
                        <div class="pull-left mt-3 mr-3" v-for="(item, index) in headerTable" :key="index" style="width:230px;">
                            <label style="font-size: 14px; font-weight: 600;">{{item}}</label>
                        </div>
                    </div>
                </div>
                <div class="w-100 pull-left px-0" v-for="(item, index) in baseForm" :key="index">
                    <div class="form-group row">
                        <div class="pull-left mt-3 mr-3" v-for="(val, key) in item" :key="key" style="width:230px;">
                            <input type="text" class="form-control form-control-sm" v-model="val.value" @keyup="autoSave(val)" :disabled="val.default || acao.status == 1 || item.readonly" v-if="val.default">
                            <input type="text" class="form-control form-control-sm" v-model="val.value" v-if="!val.default" @keyup="autoSave(val)" :disabled="acao.status == 1 || item.readonly">
                        </div>
                    </div>
                    <!-- <div class="col-sm-1 px-0 d-flex align-items-end">
                        <button class="btn btn-light btn-sm" type="button" @click="apagarLinha(index)" style="margin-bottom: 4px;">
                            <i class="fa fa-times"></i>
                        </button>
                    </div> -->
                </div>
                <!-- <div class="col-sm-12 mt-2">
                    <div class="form-group row">
                        <button class="btn btn-light" type="button" @click="novaLinha()">
                            <i class="fa fa-plus"></i> Nova linha
                        </button>
                    </div>
                </div> -->
            </div>

            <!-- BOTÕES -->
            <div class="form-group row mt-5">
                <div class="col-sm-12 d-flex justify-content-center mt-5" v-if="acao.status == 0">
                    <button class="btn btn-outline-dark" v-if="form.saving" type="button" @click="saveAction('continue')" :disabled="loadingSaveContinue || loadingSave" style="margin-right: 20px;">
                        <span v-if="!loadingSaveContinue">
                            <i class="fa fa-save"></i> Salvar e continuar depois
                        </span>
                        <span v-if="loadingSaveContinue">
                            <i class="fa fa-spinner fa-pulse"></i> Salvando
                        </span>
                    </button>
                    <button class="btn btn-outline-primary" type="button" @click="finalizeAction()" :disabled="loadingSave || loadingSaveContinue">
                        <span v-if="!loadingSave">
                            <i class="fa fa-check"></i> Concluir e ENVIAR
                        </span>
                        <span v-if="loadingSave">
                            <i class="fa fa-spinner fa-pulse"></i> Salvando
                        </span>
                    </button>
                </div>
                <div class="col-sm-12 d-flex justify-content-center mt-5" v-if="acao.status == 1 && form.reopen == 1">
                    <button class="btn btn-outline-dark" type="button" @click="reopen()" :disabled="loadingReopen" style="margin-right: 20px;">
                        <span v-if="!loadingReopen">
                            <i class="fa fa-repeat"></i> Reabrir ação
                        </span>
                        <span v-if="loadingReopen">
                            <i class="fa fa-spinner fa-pulse"></i> Reabrindo
                        </span>
                    </button>
                </div>
                <div class="col-sm-12 d-flex justify-content-center mt-5" v-if="acao.status == 1 && form.reopen == 0">
                    Ação fechada, não é possível reabri-lá.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import _ from 'lodash';

export default {
    props: [
        //"forms",
        "token",
        "client",
        "company",
        "category",
        "acao",
    ],
    data() {        
        return {
            loading: false,
            loadingSaveContinue: false,
            loadingSave: false,
            loadingAutoSave: false,
            loadingVerifPeriod: false,
            formSelected: false,
            loadingReopen: false,
            period: '',
            form: '',
            forms: [],
            baseForm: [],
            headerTable: [],
            lineCopy: [],
            saveTime: '',
            months: [],
        };
    },
    computed: {
        computedMinDate() {
            return moment().subtract(2, 'month');
        },
        computedMaxDate() {
            return moment().add(1, 'month');
        },
    },

    created() {
        this.getForms();
        if (this.acao){
            console.log("this.acao");
            console.log(this.acao);
            this.loading = true;
        }
    },

    methods: {
        setPeriod(period) {
            if (period){
                this.period = moment(period, 'MM/YYYY').format('YYYY-MM-DD');
            }
        },

        getFields(edit){
            this.loading = true;
            let dados = {
                id: this.form.id,
            };
            if (this.acao){
                dados.acao_id = this.acao.id;
            }
            axios
                .post('/api/form/get-fields', dados)
                .then((response) => {
                    console.log("getFields");
                    console.log(response.data);

                    if (response.data.fields.length > 0) {
                        this.baseForm = response.data.fields;
                    }

                    if (this.form.type == 1 && response.data.header){
                        this.headerTable = response.data.header;
                    }

                    // if (!edit){ // EDITANDO
                    //     this.openAction();
                    // }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    if (edit){ // EDITANDO
                        this.loading = false;
                    }
                });
        },

        openAction(){
            this.loading = true;
            let dados = {
                company_id: this.company.id,
                client_id: this.client.id,
                form_id: this.form.id,
                category_form_id: this.category.id,
                period: moment(this.period, 'MM/YYYY').format('YYYY-MM-DD'),
                local: 1, // Painel
                baseForm: this.baseForm,
            };
            axios
                .post('/api/form/open-action', dados)
                .then((response) => {
                    console.log("openAction");
                    console.log(response.data);
                    window.location.href = "/acoes/"+this.category.id+"/editar/"+response.data.id;
                })
                .catch(error => console.log(error))
                .finally(() => {
                    //this.loading = false
                });
        },

        reopen(){
            this.$swal({
                title: "Atenção!",
                text: "Deseja realmente reabrir a ação?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#008d4c",
                cancelButtonColor: "#d4d4d4",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(result => {
                if (result.value) {
                    this.loadingReopen = true;
                    let dados = {
                        acao_id: this.acao.id,
                        local: 1, // Painel
                    };
                    axios
                        .post('/api/form/reopen-action', dados)
                        .then((response) => {
                            console.log("reopenAction");
                            console.log(response.data);
                            this.acao.status = 0;
                        })
                        .catch(error => console.log(error))
                        .finally(() => {
                            this.loadingReopen = false;

                        });
                }
            });
        },

        finalizeAction() {
            this.$swal({
                title: "Atenção!",
                text: "Deseja realmente finalizar a ação?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#008d4c",
                cancelButtonColor: "#d4d4d4",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(result => {
                if (result.value) {
                    this.saveAction();
                }
            });
        },

        saveAction(continuar, autosave) {
            let dados = {
                acao_id: this.acao.id,
                form_id: this.form.id,
                baseForm: this.baseForm,
                period: moment(this.period, 'MM/YYYY').format('YYYY-MM-DD'),
                local: 1, // Painel
                company_id: this.company.id,
                client_id: this.client.id,
            };
            if (continuar){
                if (!autosave){
                    this.loadingSaveContinue = true;
                } else {
                    dados.autosave = autosave;
                }
                dados.continuar = true;
            } else {
                if (!autosave){
                    this.loadingSave = true;
                }
            }
            axios
                .post('/api/form/save-action', dados)
                .then((response) => {
                    console.log("saveActionContinue");
                    console.log(response.data);

                    if (!autosave){
                        this.$swal({
                            title: 'Sucesso!',
                            text: 'Dados salvos com sucesso.',
                            type: 'success',
                            showConfirmButton: true          
                        }).then((result) => {
                            if (result.value) {
                                window.location.href = "/acoes/"+this.category.id+"/lista";
                            }
                        })
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    // this.loadingSaveContinue = false;
                    // this.loadingSave = false;
                    if (autosave){
                        this.loadingAutoSave = false;
                        this.saveTime = moment().format('HH:mm:ss');
                    }
                });
        },

        novaLinha(continuar) {
            this.baseForm.push(Object.assign({}, this.lineCopy));
        },

        apagarLinha(index) {
            this.baseForm.splice(index, 1);
        },

        autoSave: _.debounce(function(value) {
            if (this.acao.status == 0){
                this.loadingAutoSave = true;
                this.saveAction(true, value);
            }
        }, 200),

        verifPeriod(){
            if (this.form){
                this.months = [];
                if (!this.acao){
                    this.period = '';
                }
                // this.months.push(moment().subtract(1, 'month').format('MM/YYYY'));
                // this.months.push(moment().format('MM/YYYY'));
                // this.months.push(moment().add(1, 'month').format('MM/YYYY'));
                var baseMonths = [
                    moment().subtract(1, 'month').format('MM/YYYY'),
                    moment().format('MM/YYYY'),
                    moment().add(1, 'month').format('MM/YYYY')
                ];
                if (this.form.unique_period == 1){
                    this.loadingVerifPeriod = true;
                    var dados = {
                        'form_id': this.form.id,
                        'company_id': this.company.id,
                    }
                    if (this.acao){
                        dados.acao_id = this.acao.id;
                    }
                    axios
                        .post('/api/form/get-forms-period',dados)
                        .then((response) => {
                            if (response.data.length > 0) {
                                for (let index = 0; index < response.data.length; index++) {
                                    for (let index2 = 0; index2 < baseMonths.length; index2++) {
                                        if (baseMonths[index2] == moment(response.data[index].period, 'YYYY-MM-DD').format('MM/YYYY')){
                                            baseMonths.splice(index2, 1);
                                        }
                                    }
                                }
                            }
                            if (this.period){
                                if (baseMonths.filter((a) => a == this.period).length == 0){
                                    baseMonths.push(this.period);
                                }
                            }
                            this.months = baseMonths.sort();
                            this.formSelected = true;
                        })
                        .catch(error => console.log(error))
                        .finally(() => {
                            this.loadingVerifPeriod = false;
                        });
                } else {
                    this.formSelected = true;
                    this.months = baseMonths;
                }
            } else {
                this.months = [];
                this.formSelected = false;
            }
        },

        getForms(){
            let dados = {
                category_form_id: this.category.id,
                local: 1, // Painel
            };
            axios
                .post('/api/form/get-forms', dados)
                .then((response) => {
                    console.log("getForms");
                    console.log(response.data);
                    this.forms = response.data;
                    
                    if (this.acao){
                        for (let index = 0; index < this.forms.length; index++) {
                            if (this.acao.form_id == this.forms[index].id){
                                this.form = this.forms[index];
                            }
                        }
                        this.period = moment(this.acao.period, 'YYYY-MM-DD').format('MM/YYYY');
                        this.getFields(true);
                        this.verifPeriod();
                        this.formSelected = true;
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    
                });
        },
        
    },
};
</script>
<style lang="scss" scoped>

</style>