<template>
  <button @click="sendRequest(route)" :class="`btn btn-${type} btn-flat`" :title="title">
    <i :class="icon"></i>
  </button>
</template>
<script>
import axios from "axios";
export default {
  props: ["route", "type", "title", "icon"],
  methods: {
    sendRequest(url) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, tenho certeza!"
      }).then(result => {
        if (result.value) {
          axios.post(url).then(res => {
            if (res.status == 200) {
              this.$swal({
                title: "Sucesso!",
                text: "Item atualizado!",
                type: "success"
              }).then(() => {
                location.reload();
              });
            } else {
              this.$swal({
                title: "Erro!",
                text: "Não foi possível executar!",
                type: "danger"
              });
            }
          });
        }
      });
    }
  }
};
</script>