<template>
    <div class="box_notify mr-4">
        <div class="dropdown dropleft">
            <i class="fa fa-bell-o" id="dropdownMenuButton" data-toggle="dropdown" @click="getNotifications()"></i>
            <span class="circle_nao_lidas" v-if="findNotRead()"></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 450px;">
                <div class="line_header">
                    Notificações
                </div>
                <div v-if="loadingNotifications" class="d-flex justify-content-center" style="width: 100%;padding: 120px 0px;">
                    <div class="spinner-grow text-secondary text-center" role="status" style="margin:0 auto;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-if="!loadingNotifications">
                    <div class="line" v-for="(item, index) in items.data.slice(0, 5)" :key="index" v-bind:class="{ 'not_read': !item.is_read }">
                        <a class="link_notification" href="notifications" @click="setRead(item.id, index, 0)">
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="msg">
                                <!-- {{item.message.length < 40 ? item.message.substring(0,40) : item.message.substring(0,40)+'...'}} -->
                                {{item.message}}
                            </div>
                            <div class="datetime">
                                {{item.date}}
                            </div>
                        </a>
                        <div class="box_read">
                            <i class="fa" data-tooltip="tooltip" data-placement="left" :title="getTitle(item.is_read)" @click="setRead(item.id, index, item.is_read)" v-bind:class="{ 'fa-circle-o':!item.is_read, 'fa-check-circle-o': item.is_read }"></i>
                        </div>
                    </div>
                    <a href="notifications" v-if="items.data.length > 0">
                        <div class="line_footer">
                            Ver todas
                        </div>
                    </a>
                    <div class="line_footer" v-if="items.data.length == 0">
                        Nenhuma notificação
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: [
        "dados",
        "client_id",
        "company_id",
    ],
    data() {
        this.getNotifications();
        return {
            //items: this.dados
            items: [],
            loadingNotifications: true
        };
    },
    created() {
        setTimeout(function() {
            $('.dropdown-menu').on('click', function (e) {
                e.stopPropagation();
            });
        }, 100);
    },
    methods: {
        findNotRead: function() {
            if (this.items.data){
                return this.items.data.find(function (item){
                    return item.is_read == 0
                })
            } else {
                return false;
            }
        },
        getTitle: function(value) {
            return value ? 'Marcar como não lida' : 'Marcar como lida';
        },

        getNotifications: function(){
            this.loadingNotifications = true;
            let dados = {
                company_id: this.company_id,
                client_id: this.client_id
            };
            axios
                .post(window.location.protocol + '//' + window.location.hostname +'/api/get-notifications', dados)
                .then((response) => {
                    this.items = response.data;
                    this.loadingNotifications = false;
                })
                .catch(error => console.log(error))
        },

        setRead: function(id, index, is_read) {
            var action = '';
            if (is_read){
                action = 'set-not-read-notification';
            } else {
                action = 'set-read-notification';
            }
            axios
                .post(window.location.protocol + '//' + window.location.hostname +'/api/'+action, {
                    id: id
                })
                .then((response) => {
                    this.items.data[index].is_read = !is_read;
                    $('[data-tooltip="tooltip"]').tooltip('dispose');
                    setTimeout(function() {
                        $('[data-tooltip="tooltip"]').tooltip();
                    }, 10);
                })
                .catch(error => console.log(error))
        },
    },
};
</script>
<style lang="scss" scoped>

</style>