<template>
  <div class="col-12 p-2 text-right" style="box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);">
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :useCustomSlot="true"
      :options="options"
      @vdropzone-queue-complete="endAndClose"
      @vdropzone-error="error"
      @vdropzone-file-added="vfileAdded"
      v-show="!edit"
    >
        <div class="col-12 mb-3">
            <!-- <i class="fa fa-angle-up fa-5x" aria-hidden="true" style="color: #c0c0c0;"></i> -->
            <img src="/img/upload.png">
        </div>
        <div class="col-12 mb-2">
            <h1 class="font-weight-bold" style="font-size: 21px;color: #2C2C2C;">Adicionar arquivos</h1>
        </div>
        <div class="col-12" style="color: #333333;">
            Clique ou arraste aqui os arquivos que deseja enviar
        </div>
    </vue-dropzone>
    <div class="text-center box_valid" v-if="validFile">
      Selecione um arquivo.
    </div>
    <br />
    <form @submit.prevent="onFormSubmit">
      <div class="form-group row">
        <label class="col-sm-12 text-center">Selecione o período *</label>
        <div class="col-sm-12">
          <month-picker required :selected="period" name="period" @getPeriod="setPeriod" style="width:200px;margin:0 auto;"></month-picker>
          <div class="text-center box_valid" v-if="validPeriod">
            Selecione um período
          </div>
        </div>
        <label class="col-sm-12 text-center mt-3">Empresa *</label>
        <div class="col-sm-12">
          <v-select placeholder="Selecione" label="fantasy_name" style="width:350px;margin:0 auto;" :reduce="label => label" v-model="company" value.sync="company" :options="companies" @input="selectedCompany"></v-select>
          <div class="text-center box_valid" v-if="validCompany">
            Selecione uma empresa
          </div>
        </div>
        <label class="col-sm-12 text-center mt-3">Contato *</label>
        <div class="col-sm-12">
          <v-select placeholder="Selecione" label="name" style="width:350px;margin:0 auto;" :reduce="label => label.id" v-model="client_id" value.sync="client_id" :options="clients" @input="selectedClient"></v-select>
          <div class="text-center box_valid" v-if="validClient">
            Selecione um contato
          </div>
        </div>

        <div class="col-sm-12 text-center mb-4 mt-3" v-if="!edit">
          <button type="submit" class="btn btn-primary mt-2 btn_enviar_insumos" :disabled="loading">
            <span v-if="!loading">
              <i class="fa fa-upload"></i> Enviar Arquivos
            </span>
            <span v-if="loading">
              <i class="fa fa-spinner fa-pulse"></i> Enviando
            </span>
          </button>
        </div>
        <div class="col-sm-12 text-center mb-4 mt-3" v-if="edit">
          <button type="button" class="btn btn-primary mt-2 btn_enviar_insumos" :disabled="loading" @click="saveEdit()">
              <span v-if="!loading">
                <i class="fa fa-check"></i> Salvar
              </span>
              <span v-if="loading">
                <i class="fa fa-spinner fa-pulse"></i> Salvando
              </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import moment from 'moment';
import axios from "axios";

export default {
  name: "app",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: ["url", "companies", "edit"],
  data: function() {
    return {
      period: null,
      sending: false,
      hasError: false,
      hasFile: false,
      validFile: false,
      validPeriod: false,
      validCompany: false,
      validClient: false,
      loading: false,
      company_code: '',
      clients: [],
      company: '',
      client_id: '',
    };
  },
  computed: {
    options() {
      return {
        url: this.url + "?period=" + this.period + "&company_code=" + this.company_code + "&client_id=" + this.client_id,
        thumbnailWidth: 150,
        thumbnailHeight: 250,
        maxFilesize: 50,
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: {
          "X-CSRF-TOKEN": decodeURIComponent(
            document.head.querySelector('[name="csrf-token"]').content
          )
        }
      };
    }
  },
  
  created() {
    if (this.edit){
      this.period = JSON.parse(this.edit).period;
      this.companies.forEach(value => {
        if (value.code == JSON.parse(this.edit).company_code){
          this.company = value;
          this.company_code = value.code;
          this.selectedCompany(value);
          this.client_id = JSON.parse(this.edit).client_id;
        }
      });
    }
  },

  methods: {
    selectedCompany(value){
      this.clients = [];
      this.client_id = '';
      if (value){
        this.company_code = value.code;
        this.validCompany = false;
      } else {
        this.company_code = '';
      }
      if (value.clients.length > 0){
        this.clients = value.clients;
      }
    },
    selectedClient(value){
      if (value){
        this.validClient = false;
      }
    },
    vfileAdded(file) {
      this.hasFile = true;
      this.validFile = false;
    },
    onFormSubmit() {
      if (!this.edit){
        if (!this.hasFile){
          this.validFile = true;
          return false;
        }
      }
      if (!this.period){
        this.validPeriod = true;
        return false;
      }
      if (!this.company_code){
        this.validCompany = true;
        return false;
      }
      if (!this.client_id){
        this.validClient = true;
        return false;
      }
      this.loading = true;
      this.sending = true;
      this.hasError = false;
      this.$refs.myVueDropzone.dropzone.options.url = this.url + "?period=" + this.period + "&company_code=" + this.company_code + "&client_id=" + this.client_id;
      this.$refs.myVueDropzone.processQueue();
    },
    setPeriod(period) {
      //this.period = period.format();
      this.period = moment(period, 'MM/YYYY').format('YYYY-MM-DD');
      this.validPeriod = false;
    },
    close() {
      this.$refs.myVueDropzone.removeAllFiles(true);
      $("#modalUpload").modal("hide");
    },
    error(files, message, xhr) {
      console.log("error");
      console.log(message);
      this.hasError = true;
      this.$swal({
        title: "Erro!",
        text: message,
        type: "danger"
      });
    },
    endAndClose(a) {
      console.log("a");
      console.log(a);
      this.sending = false;
      if (!this.hasError) {
        this.$swal({
          title: 'Sucesso!',
          text: 'Arquivo enviado com sucesso.',
          type: 'success',
          allowOutsideClick: false,
          showConfirmButton: true          
        }).then((result) => {
          console.log("result");
          console.log(result);
          if (result.value) {
            this.close();
            location.reload();
          }
        })
      }
    },

    saveEdit() {
      if (!this.period){
        this.validPeriod = true;
        return false;
      }
      if (!this.company_code){
        this.validCompany = true;
        return false;
      }
      if (!this.client_id){
        this.validClient = true;
        return false;
      }
      
      this.loading = true;
      let dados = {
          id: JSON.parse(this.edit).id,
          period: this.period,
          company_code: this.company_code,
          client_id: this.client_id,
      };
      
      axios
          .post('/api/input/update-cms', dados)
          .then((response) => {
              console.log("saveEdit");
              console.log(response.data);

              this.$swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                type: 'success',
                allowOutsideClick: false,
                showConfirmButton: true          
              }).then((result) => {
                if (result.value) {
                  this.close();
                  window.location.href = "/cms/inputs";
                }
              })
          })
          .catch(error => console.log(error));
    },
  }
};
</script>

<style lang="scss" scoped>

  .dz-progress{
    display:none !important;
  }
    .vue-dropzone {
        border: 1px dashed #E0E0E0;
        font-family: 'Roboto', sans-serif;
    }

    .box_valid{
      font-size: 13px;
      padding: 5px;
      color: red;
    }

    .btn_enviar_insumos{
        background-color: #FFFFFF !important;
        border: 1px solid #18A0FB !important;
        border-radius: 5px;
        color: #18A0FB !important;
    }

    .btn_enviar_insumos:hover {
        background-color: #18A0FB !important;
        color: #FFFFFF !important;
    }
</style>