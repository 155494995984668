<template>
    <div>
        <div class="col-12 box_nps">
            <div class="box_smile" v-for="(item, index) in numbers" :key="index" @click="selectScore(item)" :class="generateClass(item)">
                <img v-if="item < 7" src="img/sad.svg">
                <img v-if="item > 6 && item < 9" src="img/good.svg">
                <img v-if="item > 8" src="img/great.svg">
                <div>{{item}}</div>
            </div>
        </div>
        <input type="text" name="response" :id="'response_'+id" class="form-control input_hidden" required>
    </div>
</template>
<script>
export default {
    props: [
        "id",
    ],
    data() {
        return {
            numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        };
    },
    created() {
        setTimeout(function() {
            $(".box_nps").css('visibility', 'visible');
            $(".box_nps").fadeIn();
        }, 500);
    },
    methods: {
        generateClass: function(item) {
            return 'score_' + this.id + '_' + item + ' ' + 'box_smile_' + this.id;
        },
        selectScore: function(item) {
            $(".box_smile_" + this.id).removeClass('score_selected');
            $(".score_" + this.id + "_" + item).addClass('score_selected');
            $("#response_" + this.id).val(item);
        },
    },
};
</script>
<style lang="scss" scoped>
.form-control {
    width: 1px;
    height: 1px;
    border: 0;
    color: #fff;
    margin: 0 auto;
    cursor: default;
}

.form-control:focus {
    border-color: #fff;
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0) inset, 0px 0px 8px rgba(255, 255, 255, 0);
}

.box_nps {
    visibility: hidden;
    text-align: center;
    display: inline-flex;
    justify-content: center;

    .box_smile {
        margin-right: 30px;
        transition-property: margin-top;
        transition-duration: 0.4s;

        i {
            font-size: 40px;
        }

        div {
            font-size: 18px;
            margin-top: 5px;
        }
    }

    .box_smile:hover {
        cursor: pointer;
        font-weight: 700;
        margin-top: -10px;
    }

    .box_smile:last-child {
        margin-right: 0;
    }
    .score_selected {
        cursor: pointer;
        margin-top: -10px;

        div {
            background-color: #eee;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 0 auto;
        }
    }
}
</style>