<template>
    <div class="listagem_notificacoes">
        <section class="content-header" style="padding:30px;background-color: #E9E9E9;">
            <h1 style="font-size: 36px;color: #7E7E7E;font-weight: 500;"><i class="fa fa-bell-o"></i> Notificações</h1>
        </section>
        <section class="content-subheader">
            <a class="link_set_all" @click="setAllRead(items.data, company_id)">Marcar todas como lida</a>
        </section>
        <div class="line" v-for="(item, index) in items.data" :key="index" v-bind:class="{ 'not_read': !item.is_read }">
            <span class="link_notification">
                <div class="title">
                    {{item.title}}
                </div>
                <div class="msg">
                    {{item.message}}
                </div>
                <div class="datetime">
                    {{item.date}}
                </div>
            </span>
            <div class="box_read">
                <i class="fa" data-tooltip="tooltip" data-placement="left" :title="getTitle(item.is_read)" @click="setRead(item.id, index, item.is_read)" v-bind:class="{ 'fa-circle-o':!item.is_read, 'fa-check-circle-o': item.is_read }"></i>
            </div>
        </div>
        <div class="line_footer" v-if="items.data.length == 0">
            Nenhuma notificação
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: [
        "dados",
        "company_id",
    ],
    data() {
        return {
            items: this.dados,
        };
    },
    methods: {
        findNotRead: function() {
            if (this.items.data){
                return this.items.data.find(function (item){
                    return item.is_read == 0
                })
            } else {
                return false;
            }
        },
        getTitle: function(value) {
            return value ? 'Marcar como não lida' : 'Marcar como lida';
        },
        setRead: function(id, index, is_read) {
            var action = '';
            if (is_read){
                action = 'set-not-read-notification';
            } else {
                action = 'set-read-notification';
            }
            axios
                .post(window.location.protocol + '//' + window.location.hostname +'/api/'+action, {
                    id: id
                })
                .then((response) => {
                    this.items.data[index].is_read = !is_read;
                    $('[data-tooltip="tooltip"]').tooltip('dispose');
                    setTimeout(function() {
                        $('[data-tooltip="tooltip"]').tooltip();
                    }, 10);
                })
                .catch(error => console.log(error))
        },
        setAllRead: function(notifications, company_id) {
            this.$swal({
                title: "Atenção!",
                text: "Deseja realmente marcar todas as notificações como lida?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#008d4c",
                cancelButtonColor: "#d4d4d4",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
              }).then(result => {
                axios
                    .post(window.location.protocol + '//' + window.location.hostname +'/api/set-all-read-notification', {
                        client_id: notifications[0].client_id,
                        company_id: company_id,
                    })
                    .then((response) => {
                        console.log("response");
                        console.log(response);
                        notifications.map(function(value, key) {
                            notifications[key].is_read = 1;
                        });
                        this.items.data = notifications;
                        $('[data-tooltip="tooltip"]').tooltip('dispose');
                        setTimeout(function() {
                            $('[data-tooltip="tooltip"]').tooltip();
                        }, 10);
                    })
                    .catch(error => console.log(error))
              });
        },
    },
};
</script>
<style lang="scss" scoped>

</style>