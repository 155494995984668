/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)


import VueMonthlyPicker from "vue-monthly-picker";
Vue.component('vue-monthly-picker', VueMonthlyPicker);

import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse);

import { VuejsDatatableFactory } from 'vuejs-datatable';

Vue.use( VuejsDatatableFactory );

// import DataTable from 'laravel-vue-datatable';

// Vue.use(DataTable);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);
Vue.component("button-api", require("./cms/ButtonApi.vue").default);
Vue.component("company-select", require("./cms/CompanySelect.vue").default);
Vue.component("company-select-client", require("./cms/CompanySelectClient.vue").default);
Vue.component("tipo-documents-select", require("./cms/TipoDocumentsSelect.vue").default);
Vue.component("modules-web-select", require("./cms/ModulesWebSelect.vue").default);
Vue.component("client-select", require("./cms/ClientSelect.vue").default);
Vue.component("month-picker", require("./cms/MonthPicker.vue").default);
Vue.component("file-upload", require('./cms/FileUpload.vue').default);
Vue.component("formulario-cms", require("./cms/FormularioCms.vue").default);
Vue.component("formulario-select", require("./cms/FormularioSelect.vue").default);
Vue.component("formulario", require("./front/Formulario.vue").default);
Vue.component("dropzone-upload-cms", require("./cms/DropzoneUploadCMS.vue").default);

Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("dropzone-upload", require("./front/DropzoneUpload.vue").default);
Vue.component("table-painel", require("./front/TablePainel.vue").default);
Vue.component("date-range-picker", require("./front/DateRangePicker.vue").default);
Vue.component("box-notifications", require("./front/BoxNotifications.vue").default);
Vue.component("list-notifications", require("./front/ListNotifications.vue").default);
Vue.component("list-faq", require("./front/ListFaq.vue").default);
Vue.component("nps-score", require("./front/NpsScore.vue").default);
Vue.component("ui-form-painel", require("./front/UIFormPainel.vue").default);
Vue.component("ui-form-ticket", require("./front/UIFormTicket.vue").default);
Vue.component("ui-textarea-ticket", require("./front/UITextareaTicket.vue").default);
Vue.component("table-support-tickets", require("./front/TableSupportTickets.vue").default);

const app = new Vue({
    el: "#app"
});

$('[data-tooltip="tooltip"]').tooltip();